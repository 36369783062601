<template>
  <div>
    <EITTerm />
  </div>
</template>

<script>
  import EITTerm from '@/components/eye/EITTerm.vue'

export default {
  components: {
    EITTerm
  }
}
</script>
