<template>
  <v-container class="py-6">
    <EITHeader />
    <div>
      <br />
      <br />

      This injection tracking calculator is provided as a tool for ophthalmologists and technicians to assist in treatment planning and scheduling. It is not intended to replace professional medical judgment or serve as a substitute for clinical expertise.<br />
      <br />
      The calculator generates treatment dates based on the information entered and established treatment protocols. While efforts have been made to ensure the accuracy and reliability of the calculations. The calculated dates should be reviewed and confirmed by the treating healthcare professional.<br />
      <br />
      No personal information or data is collected or recorded by the calculator or the website.<br />
      <br />
      Users of this calculator should exercise their professional judgment and consider the unique characteristics of each patient when determining the appropriate treatment schedule. The creators and operators of this website shall not be liable for any damages resulting from the use of this calculator or the information provided on this website.<br />
      <br />
      By using the calculator on this website, you acknowledge that you have read, understood, and agreed to this disclaimer.<br />
      <a href="/">back to home</a>
    </div>
  </v-container>
</template>

<script>
  import EITHeader from '@/components/eye/EITHeader.vue'

  export default {
    components: {
      EITHeader,
    }
  }
</script>
